import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import { TextareaAutosize } from "@mui/material";
import DropDownPayment from "../../components/DropDownPayment";
import DropDown from "../../components/Dropdown";
import Axios from "../../axios";

function SubscriptionForm({ formData, setFormData }) {

  const period= [
    {
      label: 'MONTHLY',
      value: 'MONTHLY'
    },
    {
      label: 'DAILY',
      value: 'DAILY'
    },
    {
      label: 'WEEKLY',
      value: 'WEEKLY'
    },
    {
      label: 'QUARTERLY',
      value: 'QUARTERLY'
    },
    {
      label: 'HALFYEARLY',
      value: 'HALFYEARLY'
    },
    {
      label: 'ANNUALLY',
      value: 'ANNUALLY'
    },
    {
      label: 'NINEMONTH',
      value: 'NINEMONTH'
    }
  ];

  useEffect(() => {

  }, []);



  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            {/* textfield as a search, you search for the user and then we'll 
           fetch sporttypes, category and number of months from database, 
           then we'll enter the amount and the credit etc.. */}
            <TextField
              label="Name"
              id="name"
              type="text"
              value={formData.name || ""}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              sx={{ m: 0, width: "25ch" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Actual Price"
              id="actualPrice"
              type="number"
              value={formData.actualPrice || ""}
              onChange={(e) => setFormData({ ...formData, actualPrice: Number(e.target.value) })}
              sx={{ m: 0, width: "25ch" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Display Price"
              id="displayPrice"
              type="number"
              value={formData.displayPrice || ""}
              onChange={(e) => setFormData({ ...formData, displayPrice: Number(e.target.value) })}
              sx={{ m: 0, width: "25ch" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextareaAutosize
              minRows={4}
              label="Description"
              id="description"
              value={formData.description || ""}
              type="text"
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              sx={{ m: 0, width: "20ch" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              labelId="select-basic"
              id="demo-simple-select"
              value={formData.period || 'MONTHLY'}
              label="Period"
              onChange={(e) => setFormData({ ...formData, period: e.target.value })}
              size="small"
            >
              {period.map((name) => (
                <MenuItem
                  key={name.label}
                  value={name.value}
                >
                  {name.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default SubscriptionForm;

// number of months
// type de sport

// montant //credit switch button

SubscriptionForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};