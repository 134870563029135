const { default: axios } = require("axios");

const instance = axios.create({
  baseURL: "https://api.bblfitness.in",
});
instance.interceptors.request.use((config) => {
  const authToken = window.localStorage.getItem("access_token");
  if (authToken) {
    config.headers['x-access-token'] = authToken;
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});
export default instance;
