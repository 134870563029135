import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as formatDate from "../../utils/formatTime";

import MultiDropdown from "../../components/MultiDropdown";
import Dropdown from "../../components/Dropdown";
import Axios from "../../axios";


function PlanForm({ formData, setFormData }) {
  const sports = ["Taekwondo", "karate", "football", "full contact"];
  const category = ["Minim", "Cadet", "Junior", "Senior", "Professional"];
  const [sportTypes, setSportTypes] = useState([]);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    Axios.get(`/api/sportTypes/`).then((response) => {
      const res = response.data.map(value => value.name);
      setSportTypes(res);
    });
    Axios.get(`/api/subscriptions/`).then((response) => {
      setSubscription(response.data);
    });
  }, []);

  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <MultiDropdown
              data={formData}
              setData={setFormData}
              names={sportTypes}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown data={formData} setData={setFormData} names={category} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: 200 }}>
              <InputLabel id="select-basic" size="small">
                Subscription Plan
              </InputLabel>
              <Select
                labelId="select-basic"
                id="demo-simple-select"
                value={formData.subscriptionId}
                label="Subscription Plan"
                onChange={(e) => {
                  setFormData({ ...formData, subscriptionId: e.target.value });
                }}
                size="small"
              >
                {subscription.map((name) => (
                  <MenuItem
                    key={name._id}
                    value={name._id}
                  >
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* start date */}
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                id="startDate"
                value={formData.startDate || null}
                onChange={(e) => {
                  setFormData({ ...formData, startDate: e });
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                id="endDate"
                value={formData.endDate || null}
                onChange={(e) => {
                  setFormData({ ...formData, endDate: e });
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="months"
              type="number"
              name="months"
              fullWidth
              value={formData.months || ""}
              id="months"
              label="Months"
              size="small"
              onChange={(e) => {
                setFormData({ ...formData, months: e.target.value });
                // console.log(formData)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="amount"
              name="amount"
              type="number"
              fullWidth
              value={formData.amount || ""}
              id="amount"
              label="Amount"
              size="small"
              onChange={(e) => {
                setFormData({ ...formData, amount: e.target.value });
                // console.log(formData)
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default PlanForm;
