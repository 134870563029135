import { useState, useMemo } from "react";
import { useRoutes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import { SnackbarProvider } from "notistack";
import { ToastContainer } from 'react-toastify';
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";

import { UserContext } from "./UserContext";

// ----------------------------------------------------------------------

export default function App() {
  const [user, setUser] = useState({});

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const isAdmin = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).roles.includes('Admin');
  const routing = useRoutes(Router(localStorage.getItem("user"), isAdmin));

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <SnackbarProvider>
        <UserContext.Provider value={value}>
          {routing}
        </UserContext.Provider>
      </SnackbarProvider>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </ThemeConfig>
  );
}
