import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddDocument from '../../../pages/AddDocument';
import Iconify from '../../../components/Iconify';

import AddMember from "../../../pages/AddMember";
import Axios from '../../../axios';


// ----------------------------------------------------------------------

export default function SubscriptionMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [documentOpen, setDocumentOpen] = useState(false);

  const { menu, setMenu, setSubscriptions } = props;


  const handleDelete  = () => {
    if (window.confirm(`Are you sure you want to delete ${props.subscription.name} ?`)) {
      // Save it!
        Axios.put(`/api/subscription/${props.subscription._id}`, {
          isDeleted:true
      }).then((response) => {
        if(response.message) {
          console.log("An error has occured")
        }
        else {
          // rerender table component
          setMenu(!menu)
        }
      })
      
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  const handleUpdate = () => setOpen(!open);
 useEffect(() => {
    Axios.get(`/api/subscriptions/`).then((response) => {
      setSubscriptions(response.data);
    });
  }, [open]);


  return (
    <>
      {/* <AddMember isOpen={open} toggle={handleUpdate} edit={open} member={props.member} /> */}
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
{/* 
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={handleUpdate}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
